/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Purchaseclass.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { Col, Row, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RazorPayB from "../../../components/RazorPay/razorPay";
import Select from 'react-select';
import { RotatingLines } from "react-loader-spinner";
import { IoLogoWhatsapp } from "react-icons/io";

export default function Purchaseclass() {
  let localData = JSON.parse(localStorage.getItem("userData")!);
  const location: any = useLocation();
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Proceed to Pay');

  let dateTimeArr = location?.state?.dataArr
  let remainingArr = location?.state?.remainigData

  const startTimesLengths = dateTimeArr?.reduce((acc: any, item: any) => acc + item?.start_time?.length, 0);
  let mergeDateTime = startTimesLengths == location?.state?.state?.count ? dateTimeArr : dateTimeArr?.concat(remainingArr);
  const filteredData = mergeDateTime?.filter((item: any) => item.start_time.length > 0);

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  const navigate = useHistory();

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  const [couponCode, setCouponCode] = useState<any>([]);
  const [couponVal, setCouponVal] = useState<any>([]);
  const [currency, setCurrency] = useState<any>("");
  const [no_of_users, setno_of_users] = useState<any>('');
  const [discountCoupon, setDiscountCoupon] = useState<any>({ coupon: "", discount: "", apply: false });
  const [expiredCoupon, setExpiredCoupon] = useState<any>(false);
  const [price, setPrice] = useState(0);
  const [err, setErr] = useState<any>("");

  function percentageStringToNumber(percentageString: any) {
    const cleanedString = percentageString?.replace?.('%', '');
    const numberValue = parseFloat(cleanedString);
    const result = numberValue / 100;
    return result || 0;
  }

  let discount = location?.state?.state?.details?.item?.free_discount
  let referralDiscount: any = location?.state?.state?.count <= location?.state?.state?.details?.item?.free_class ? "0" : (location?.state?.state?.count * location?.state?.state?.price) * percentageStringToNumber(discount);
  let freeClass: any = location?.state?.state?.details?.item?.free_class
  let freeClassMoney = freeClass * location?.state?.state?.price
  let countNum = location?.state?.state?.count
  let actualAmount: any = location?.state?.state?.count <= location?.state?.state?.details?.item?.free_class ? "0" : (location?.state?.state?.price * countNum) - (location?.state?.state?.price * location?.state?.state?.details?.item?.free_class)
  let finalDiscount: any = location?.state?.state?.count <= location?.state?.state?.details?.item?.free_class ? "0" : referralDiscount
  // let finalGST: any = location?.state?.state?.count <= location?.state?.state?.details?.item?.free_class ? "0" :
  //   (location?.state?.state?.price * location?.state?.state?.count * 18) / 100
  // let gstCurrency = currency == "INR" ? finalGST : 0
  let amount_to_pay: any = location?.state?.state?.price * location?.state?.state?.count - freeClass * location?.state?.state?.price || 0
  const [fatp] = useState(amount_to_pay);
  let amountAfterDiscount = discountCoupon.apply === true ? fatp - fatp * discountCoupon.discount : fatp
  const [afd] = useState(amountAfterDiscount);
  let discountAmount: any = discountCoupon.apply == true ? +finalDiscount + (afd * (+discountCoupon.discount)) : +finalDiscount
  localStorage.setItem("discountAmount", discountAmount);
  let noOfClasses: any = location?.state?.state?.count <= location?.state?.state?.details?.item?.free_class ?
    0 : (+location?.state?.state?.count) - (+location?.state?.state?.details?.item?.free_class);
  let baseAmount = amount_to_pay - discountAmount
  let gstAmtCalc: any = currency == "INR" ? (baseAmount * 18) / 100 : 0;
  localStorage.setItem("gstAmtCalc", gstAmtCalc?.toFixed?.(2));
  let finalAmountToPay: any = discountCoupon.apply === true && !expiredCoupon ? ((+location?.state?.state?.price * location?.state?.state?.count) + gstAmtCalc) - ((+location?.state?.state?.price * location?.state?.state?.count - freeClassMoney) * discountCoupon.discount) - freeClassMoney :
    (location?.state?.state?.price * location?.state?.state?.count - freeClassMoney) + (gstAmtCalc == 0 ? "" : gstAmtCalc) - finalDiscount
  localStorage.setItem("finalAmountToPay", finalAmountToPay?.toFixed?.(2));

  console.log(location?.state?.state?.details?.item?.plans[0]?.Planfrequencys.some((item: any) =>
    item?.currency == localData?.currency?.slice(0, 3)), "ConsoleSureee12344")
  // --------------------------------STUDENT ENROLL API-------------------------------------//
  function studentEnroll() {
    let localDiscount: any = localStorage.getItem("discountAmount")!;
    let finalAmountToPay = localStorage.getItem("finalAmountToPay")!;
    let gstAmtCalc: any = localStorage.getItem("gstAmtCalc")!;
    let superCredit: any = freeClass * location?.state?.state?.price || 0
    let formdata = new FormData();
    formdata.append('student_apply_for_demo_id', location?.state?.state?.details?.item?.studentapplyfordemo_id ??
      location?.state?.state?.details?.item?.student_apply_for_demo_id);
    formdata.append('no_of_class', location?.state?.state?.count);
    formdata.append('teacher_id', location?.state?.state?.details?.item?.teacher_assign_id ??
      location?.state?.state?.details?.item?.teacher_id);
    formdata.append('coupon_code', couponCode);
    formdata.append('price_per_session', location?.state?.state?.price);
    formdata.append('actual_price', amount_to_pay?.toFixed(2));
    formdata.append('actual_amount', amount_to_pay);
    formdata.append('gst_amount', gstAmtCalc);
    formdata.append('amount', actualAmount?.toFixed?.(2));
    formdata.append('discount_amount', localDiscount);
    formdata.append('amount_to_pay', finalAmountToPay);
    formdata.append('teacher_name', location.state.state?.details?.teacher_name ?? location.state.state?.details?.item?.teacher_name);
    formdata.append('data', JSON.stringify(filteredData));
    formdata.append('transation_id', "transation_id");
    formdata.append(location?.state?.state?.details?.item?.class_id ? 'class_id' : "category_id",
      location?.state?.state?.details?.item?.class_id ?? location?.state?.state?.details?.item?.category_id ?? 0);
    formdata.append(location?.state?.state?.details?.item?.subject_id ? 'subject_id' : "course_id",
      location?.state?.state?.details?.item?.subject_id ?? location?.state?.state?.details?.item?.course_id ?? 0);
    formdata.append('payment_status', "successfully");
    formdata.append('plan_id', location?.state?.state?.details?.item?.plan_id);
    formdata.append('studen_free_class', location?.state?.state?.details?.item?.free_class);
    formdata.append('currency', location?.state?.state?.details?.item?.plans[0]?.Planfrequencys.some((item: any) =>
      item?.currency == localData?.currency?.slice(0, 3)) ? localData?.currency?.slice(0, 3) : "USD")
    formdata.append('subject_name', location?.state?.state?.details?.item?.subject_name ?
      location?.state?.state?.details?.item?.subject_name :
      location?.state?.state?.details?.item?.course_name);
    formdata.append('super_credit', superCredit);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/enrollStudent`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token")!,
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/home',
            });
          }, 1000);
          localStorage.removeItem("discountAmount");
          localStorage.removeItem("finalAmountToPay");
          localStorage.removeItem("gstAmtCalc");
        }
        else {
          setLoadingSpinner('Proceed to Pay')
          setTimenotify(response?.data?.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        console.log("Err:", error);
        notify("Something went Wrong, Please try again later");
        setLoadingSpinner('Proceed to Pay')
      });
  }

  if (freeClass >= countNum) {
    location?.state?.state?.price == 0;
  }

  // --------------------------------GET COUPON API-----------------------------------//
  function getCoupon() {
    let formdata = new FormData();
    formdata.append('user_id', localData?.id)

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/all_coupon`,
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        response.data.data.forEach((item: any) => {
          setno_of_users(item?.no_of_users)
          setCouponCode((prev: any) => {
            return [...prev, { label: item?.coupon_code, value: item?.coupon_code, discount: item?.discount_percentage }]
          })
          setCouponVal((prev: any) => {
            return [...prev, { label: item?.coupon_code, value: item?.coupon_code, discount: item?.discount_percentage }]
          })
        })
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  // --------------------------------APPLY COUPON API-----------------------------------//
  function applyCoupon() {
    let formdata = new FormData();
    formdata.append('user_id', no_of_users == 0 ? "" : localData?.id)
    formdata.append('coupon_code', discountCoupon?.coupon)
    formdata.append('no_of_classes', noOfClasses)

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/apply_coupon`,
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
        else {
          setErr(response?.data?.message)
          setExpiredCoupon(true);
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  // FOR DEFAULT CURRENCY
  // useEffect(() => {
  //   if (location?.state?.state?.details?.item?.plans[0]?.Planfrequencys?.some((item: any) => item?.currency == localData?.currency?.slice(0, 3))) {
  //     return (
  //       location?.state?.state?.details?.item?.plans[0]?.Planfrequencys.map((item: any) => {
  //         if (item?.currency == localData?.currency?.slice(0, 3)) {
  //           setCurrency(item?.currency);
  //         }
  //         else {
  //           setCurrency('USD')
  //         }
  //       })
  //     )
  //   }
  // }, [currency])

  useEffect(() => {
    // Check if the user's currency is present in Planfrequencys
    const userCurrency = localData?.currency?.slice(0, 3);
    const plans = location?.state?.state?.details?.item?.plans[0]?.Planfrequencys || [];

    const matchingPlan = plans.find((item: any) => item.currency === userCurrency);
    const defaultPlan = plans.find((item: any) => item.currency === "USD");

    if (matchingPlan) {
      setCurrency(matchingPlan.currency);
      setPrice(matchingPlan.one_session * location?.state?.state?.count);
    } else if (defaultPlan) {
      setCurrency(defaultPlan.currency);
      setPrice(defaultPlan.one_session * location?.state?.state?.count);
    } else {
      setCurrency("USD");
      setPrice(location?.state?.state?.price * location?.state?.state?.count || 0);
    }
  }, [location, localData]);

  useEffect(() => {
    getCoupon();
    window.scrollTo(0, 0);
  }, [])

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: expiredCoupon ? '1px solid red' : provided.border,
    }),
  };

  const handleBackClick = () => {
    navigate.goBack();
  };

  return (
    <>
      <ToastContainer limit={1} />
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <span onClick={handleBackClick} className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </span>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Purchase Class</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <div className={`container ${cx.contentBody}`}>
            <div className={`${cx.cardBody}`}>
              <div className={`${cx.purchaseBox}`}>
                <div className={`${cx.purchaseBox}`}>
                  <p>No. of Classes -</p>
                  <p className={`${cx.purchaseNo}`}>{location?.state?.state?.count}</p>
                </div>
                <div className={`${cx.purchaseBox}`}>
                  <p>Actual Price -</p>
                  <p>
                    <span>
                      {localData?.currency?.slice(0, 3) == currency ? currency : "USD"} {(location?.state?.state?.price * location?.state?.state?.count)?.toFixed(2)}
                    </span>
                  </p>
                </div>
              </div>

              <Row>
                <Col className="col-9">
                  <div className={`mt-4 mb-4 ${cx.referNow}`}>
                    <Form.Group aria-label="Default select example">
                      {finalAmountToPay <= 0 ?
                        <Select
                          isDisabled
                          defaultValue={couponVal}
                          options={couponCode}
                          placeholder={`You can't apply a coupon to a ${localData?.currency?.slice(0, 3) == currency ? currency : "USD"} 0 bill.`} />
                        : <Select
                          styles={expiredCoupon && customStyles}
                          defaultValue={couponVal}
                          options={couponCode}
                          placeholder="Select Coupon Code"
                          onChange={(e: any) => {
                            setExpiredCoupon(false);
                            setDiscountCoupon({
                              coupon: e.value,
                              discount: percentageStringToNumber(e.discount),
                              apply: false
                            });
                          }} />}
                    </Form.Group>
                    <span className="text-danger">{err}</span>
                  </div>
                </Col>

                <Col className="col-3 ">
                  <div className={`mt-4 mb-4 ${cx.referNow}`}>
                    <input
                      className={`${cx.referCopy}`}
                      type="Submit"
                      onClick={() => {
                        setDiscountCoupon((prev: any) => {
                          return { ...prev, apply: true }
                        });
                        applyCoupon();
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <div className={`${cx.tableBorder}`}>
                <div className={`${cx.borderBottom}`}>
                  <div className={`${cx.purchaseTable}`}>
                    <p>Actual Amount</p>
                    <p><span>{localData?.currency?.slice(0, 3) == currency ? currency : "USD"} {(location?.state?.state?.price * location?.state?.state?.count)?.toFixed(2)}</span></p>
                  </div>

                  {freeClass && <div className={`${cx.purchaseTable}`}>
                    <p>Super Credit</p>
                    <p><span>{freeClass ?? 0} x {localData?.currency?.slice(0, 3) == currency ? currency : "USD"} {location?.state?.state?.price} = {localData?.currency?.slice(0, 3) == currency ? currency : "USD"} {freeClass * location?.state?.state?.price || 0}</span></p>
                  </div>}

                  <div className={`${cx.purchaseTable}`}>
                    <p>Discount</p>
                    <p><span>{localData?.currency?.slice(0, 3) == currency ? currency : "USD"} {expiredCoupon ? 0 : discountAmount?.toFixed?.(2)}</span></p>
                  </div>

                  {currency && localData?.currency?.slice(0, 3) == "INR" ?
                    <div className={`${cx.purchaseTable}`}>
                      <p>GST</p>
                      <p><span>
                        {localData?.currency?.slice(0, 3) == currency ? currency : "USD"} {gstAmtCalc < 0 ? 0 : gstAmtCalc?.toFixed?.(2)}
                      </span></p>
                    </div> : ""}
                </div>

                <div className={`${cx.borderBottom}`}>
                  <div className={`${cx.purchaseTable}`}>
                    <p>Amount to Pay</p>
                    <p><span>{localData?.currency?.slice(0, 3) == currency ? currency : "USD"} {location?.state?.state?.count <= location?.state?.state?.details?.item?.free_class ? "0" : finalAmountToPay?.toFixed?.(2)} </span></p>
                  </div>
                </div>
              </div>

              <Col className="col-12">
                <RazorPayB studentEnrollHandler={studentEnroll}
                  prize={location?.state?.state?.count <= location?.state?.state?.details?.item?.free_class ? "0" : finalAmountToPay?.toFixed?.(2)}
                  loadingStatus={loadingSpinner}
                  discount={discountAmount}
                  currency={localData?.currency?.slice(0, 3) == currency ? currency : "USD"} />
              </Col>
              <Col className="col-12">
                <div className={`${cx.whatsapp_add}`}>
                  <a target="_blank" href="https://api.whatsapp.com/send?phone=918440868293"
                    className={`${cx.add_number}`}>
                    Want to pay through any other method? Reach out to us at WhatsApp no
                    <IoLogoWhatsapp />
                  </a>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  );
}
import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Classdetails.module.scss";
import axios from "axios";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdArrowBackIos, MdOutlineAccessTime, MdOutlineClose, } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import moment from "moment";
import "moment/locale/es";
import mtimezone from "moment-timezone";
import { RotatingLines } from "react-loader-spinner";

export default function Classdetails(props: any) {
  // function valuetext(value: number) {
  //   return `${value}°C`;
  // }
  let localData = JSON.parse(localStorage.getItem("userData")!);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [startDate, setStartDate] = useState(new Date());
  const [disabled1, setDisabled1] = useState(true);
  const [disabled2, setDisabled2] = useState(true);
  const [disabled3, setDisabled3] = useState(true);
  const [disabled4, setDisabled4] = useState(true);
  const [disabled5, setDisabled5] = useState(true);
  const [disabled6, setDisabled6] = useState(true);

  const location: any = useLocation();

  //----validation----//
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const calculateUtcDate = (date: string, time: string, timezone: string) => {
    if (!date || !time) return "";
    return mtimezone
      .tz(`${date} ${time}`, timezone)
      .utc()
      .format();
  };


  const navigate = useHistory();
  const [value, setValue] = useState<any[]>([]);
  const [currSymbol, setCurrSymbol] = useState("");
  const [preferred_topic, setPreferred_topic] = useState("");
  const [preferred_time, setPreferred_time] = useState("");
  const [plan_id, setPlanId] = useState("");
  const [forSearchData, setForSearchData] = useState<any>();
  const [curriculam, setCurriculam] = useState('')
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Apply for Demo')
  let [disablebtn, setDisablebtn] = useState<any>(false)
  const [classSchedule, setClassSchedule] = useState<any>([
    {
      selectedDateForDatePicker: "",
      select_date: "",
      select_time: "",
      select_end_time: "",
      utc_select_time: "",
      utc_select_end_time: "",
      newDate: "",
      utc_date: "",
    },
    {
      selectedDateForDatePicker: "",
      select_date: "",
      select_time: "",
      select_end_time: "",
      utc_select_time: "",
      utc_select_end_time: "",
      newDate: "",
      utc_date: "",
    },
    {
      selectedDateForDatePicker: "",
      select_date: "",
      select_time: "",
      select_end_time: "",
      utc_select_time: "",
      utc_select_end_time: "",
      newDate: "",
      utc_date: "",
    },
  ]);

  const validateClassSchedule = () => {
    let isValid = true;
    classSchedule.forEach((schedule: any, index: any) => {
      if (schedule?.newDate && !schedule?.utc_select_time) {
        isValid = false;
        toast.error(`Time is required for schedule at Preference ${index + 1}.`);
      }
    });
    return isValid;
  };

  //-----------------------------STUDENT APPLY FOR DEMO API---------------------------//
  function classDetails() {
    classSchedule.map((item: any, index: number) => {
      if (item.select_end_time === "") {
        classSchedule?.splice(index, classSchedule.length);
      }
    });

    let priceOne = value[0]?.toString();
    let priceTwo = value[1]?.toString();

    let formdata = new FormData();
    formdata.append("userId", localData?.id);
    formdata.append("class_id", location?.state?.class_id || location?.state?.classId);
    formdata.append("currency", currSymbol || location?.state?.plans[0]?.Planfrequencys[0]?.currency);
    formdata.append("subject_id", location?.state?.id || location?.state?.subjectId);
    formdata.append("category_id", location?.state?.category_id);
    formdata.append("course_id", location?.state?.courseId);
    formdata.append("preferred_topic", preferred_topic);
    formdata.append("preferred_time", preferred_time);
    formdata.append("plan_id", priceOne == undefined ? null : plan_id || location?.state?.plans[0]?.id);
    formdata.append("select_price_range", priceOne + "-" + priceTwo || location?.state?.plans[0]?.selectPlan?.Planfrequencys[0]?.start_range + "-" + location?.state?.plans[0]?.selectPlan?.Planfrequencys[0]?.end_range);
    formdata.append("data", JSON.stringify(classSchedule));
    formdata.append("title", location?.state?.title || location?.state?.subjectName || location?.state?.courseDetails?.name);
    formdata.append("imageUrl", JSON.stringify(location.state.imageUrl + location.state.image));
    formdata.append("curriculam", curriculam);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/studentapplyforademo`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setDisablebtn(true)
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          handleClose();
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: "/student/home",
              state: {
                priceRange: `${priceOne} - ${priceTwo}`,
                data: classSchedule,
              },
            });
          }, 2000);
        } else {
          handleClose();
          setLoadingSpinner('Apply for Demo')
          notify(response.data.message)
          setDisablebtn(false)
        }
      })
      .catch(function (error) {
        handleClose()
        setLoadingSpinner('Apply for Demo')
        setDisablebtn(false)
        notify(error?.response?.data?.message);
      });
  }

  // const handleChangeDate = (e: any, index: number) => {
  //   let formatter = 'YYYY-MM-DD[T]HH:mm:ss';
  //   let currentdatetime = moment(e.toUTCString()).format(formatter);
  //   let mtimedate = mtimezone.utc(e).tz(localData?.timezone).format();
  //   setClassSchedule((prev: any) => {
  //     prev[index].selectedDateForDatePicker = e;
  //     prev[index].select_date = mtimedate;
  //     prev[index].newDate = currentdatetime
  //     return prev;
  //   });
  // };

  const handleChangeDate = (e: any, index: number) => {
    const formatter = "YYYY-MM-DD[T]HH:mm:ss";
    const currentdatetime = moment(e.toUTCString()).format(formatter);
    const mtimedate = mtimezone.utc(e).tz(localData?.timezone).format();

    setClassSchedule((prev: any) => {
      const updatedSchedule = [...prev];
      const currentTime = updatedSchedule[index]?.select_time || "";
      const utcDate = calculateUtcDate(currentdatetime.split("T")[0], currentTime, localData?.timezone);

      updatedSchedule[index] = {
        ...updatedSchedule[index],
        selectedDateForDatePicker: e,
        select_date: mtimedate,
        newDate: currentdatetime,
        utc_date: utcDate,
      };
      return updatedSchedule;
    });
  };

  useEffect(() => {
    // Get the default selected plan and local currency
    const localCurrency = localData?.currency?.split("-")[0] || "USD"; // Default to USD if not available
    const defaultPlan = location?.state?.plans[0]; // Assuming first plan as default if no selection

    if (defaultPlan?.Planfrequencys?.length > 0) {
      // Find matching Planfrequency for local currency
      const matchingPlanFrequency = defaultPlan.Planfrequencys.find(
        (freq: any) => freq.currency === localCurrency
      );

      if (matchingPlanFrequency) {
        // Use the matching Planfrequency
        setValue([
          matchingPlanFrequency.start_range,
          matchingPlanFrequency.end_range,
        ]);
        setCurrSymbol(matchingPlanFrequency.currency);
      } else {
        // Fallback to USD if local currency doesn't match
        const usdPlanFrequency = defaultPlan.Planfrequencys.find(
          (freq: any) => freq.currency === "USD"
        );

        if (usdPlanFrequency) {
          setValue([usdPlanFrequency.start_range, usdPlanFrequency.end_range]);
          setCurrSymbol("USD");
        } else {
          // If USD is not available, fallback to the first Planfrequency
          const defaultPlanFrequency = defaultPlan.Planfrequencys[0];
          setValue([
            defaultPlanFrequency.start_range,
            defaultPlanFrequency.end_range,
          ]);
          setCurrSymbol(defaultPlanFrequency.currency || "USD");
        }
      }
      setPlanId(defaultPlan?.id);
    } else {
      // If no Planfrequencys are available
      setValue([0, 0]);
      setCurrSymbol("USD");
    }
  }, []);


  const handleChangeTime = (e: any, index: number) => {
    moment().locale(localData?.timezone);
    const datechangebasedontime = mtimezone.tz(`${classSchedule[index]?.newDate?.split("T")[0]} ${e}`, localData?.timezone).utc().format();
    let modifiedStarttime = datechangebasedontime?.split("T")[1]?.slice(0, 5);
    let modifieddate = datechangebasedontime?.split("T")[0] + "T" + modifiedStarttime + ":00.000Z";

    setClassSchedule((prev: any) => {
      prev[index].select_date = prev[index]?.newDate;
      prev[index].utc_select_time = modifiedStarttime;
      prev[index].utc_date = modifieddate;
      prev[index].select_time = e;
      return prev;
    });
    let hours = modifiedStarttime.slice(0, 2);
    let afterHours = modifiedStarttime.slice(2);
    let time = +hours + 1;
    let fullEndTime =
      time > 23
        ? `0${24 - time}${afterHours}`
        : time < 10
          ? `0${time}${afterHours}`
          : `${time}${afterHours}`;
    let localhours = e.slice(0, 2);
    let localafterHours = e.slice(2);
    let localtime = +localhours + 1;
    let localfullEndTime =
      localtime > 23
        ? `0${24 - localtime}${localafterHours}`
        : localtime < 10
          ? `0${localtime}${localafterHours}`
          : `${localtime}${localafterHours}`;
    setClassSchedule((prev: any) => {
      prev[index].select_end_time = localfullEndTime;
      prev[index].utc_select_end_time = fullEndTime;
      return prev;
    });
  };

  // --------------------------------CLASS/CATEGORY API-----------------------------------//
  function getCategoryId(e: any) {
    let formdata = new FormData();
    formdata.append("userId", localData.id);
    formdata.append("type", "All");
    formdata.append("class_id", location?.state?.class_id);
    formdata.append("category_id", location?.state?.category_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/gethomeScreen`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token")!,
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          let arrClsCat: any = [];
          response.data.data[0]?.category?.map((e: any) => {
            e?.course_Details?.map((a: any) => {
              arrClsCat.push({
                type: "category_subject",
                name: e.name,
                data: a,
              });
            });
            arrClsCat.push({
              type: "category",
              data: e,
            });
          });
          response.data.data[0]?.classes?.map((e: any) => {
            e?.subject_Details?.map((a: any) => {
              arrClsCat.push({
                type: "class_subject",
                name: e.class_name,
                data: a,
              });
            });
            arrClsCat.push({
              type: "class",
              data: e,
            });
          });
          response.data.data[0]?.demo_request?.map((e: any) => {
            arrClsCat.push({
              type: "demo_request",
              data: e,
            });
          });
          // setClassOrCategory(e);
          setForSearchData(arrClsCat);
          // setImage(response.data?.data[0]?.subject_images);
          // setCourse_images(response.data?.data[0]?.course_images);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  const searchFilter = (item: any, type: String) => {
    if (type === "classes") {
      // setClassOrCategory("classes");
      // setSubject(item?.subject_Details);
    } else {
      // setClassOrCategory("category");
      // setSubject(item?.course_Details);
    }
  };

  useEffect(() => {
    getCategoryId("classes");
  }, []);

  // MINUS ONE DAY ACCORDING TO TIMEZONE
  let date = new Date();
  let intlDateObj = new Intl.DateTimeFormat('en-US', { timeZone: localData?.timezone });
  let newTimezone: any = intlDateObj.format(date);
  let splitDate = newTimezone.split("/")
  let minusDate = splitDate[1] == "1" ? 2 : splitDate[1] - 1
  let FinalDate = splitDate[0] + "/" + minusDate + "/" + splitDate[2]

  return (
    <>
      <ToastContainer limit={1} />
      <Header forSearchData={forSearchData} searchFilter={searchFilter} />
      <ToastContainer />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/home" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Sub Category</h6>
                </Col>
                <Col className={`col-2 text-end`}></Col>
              </Row>
            </div>
          </div>
          <div className={`${st.spaceBox}`}></div>
          <div className={`container ${cx.contentBody} mt-3`}>
            <div className={`${cx.detailsBox}`}>
              <img src={location?.state?.imageUrl + location?.state?.image || location?.state?.url + location?.state?.subject_details?.image || location?.state?.url + "/course/" + location?.state?.courseDetails?.image} />
              <h5>{location?.state?.title || location?.state?.subjectName || location?.state?.courseDetails?.name}</h5>
              {/* <p>{removeTags(location?.state?.description) || removeTags(location?.state?.subject_details?.description) || removeTags(location?.state?.courseDetails?.sort_description)}</p> */}
              <div
                dangerouslySetInnerHTML={{ __html: location?.state?.description || location?.state?.subject_details?.description || location?.state?.courseDetails?.sort_description }}
              />
              <span className={`btn mt-4 ${cx.bookBtn}`} onClick={handleShow}>
                Apply for Demo
              </span>
            </div>
          </div>
          <div className={`pt-3`}></div>
        </div>
      </section>
      <Footer />

      <div className={`${cx.popupBox} ${show && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.popupTitle}`}>
            <h5>
              {" "}
              Book a FREE session for <br /> your child
              <i className={`${cx.closeIcon}`} onClick={handleClose}>
                <MdOutlineClose />
              </i>
            </h5>
          </div>

          <div className={`${cx.cotentBody}`}>

            <Col className="col-12">
              <div className={`${cx.profileEdit}`}>
                <label className="mt-3">Select Plan</label>
                <Form.Select
                  onChange={(e: any) => {
                    const localCurrency = localData?.currency?.split("-")[0]; // Default to USD if localData.currency is not available
                    const selectedPlan = location?.state?.plans[e.target.value]; // Get the selected plan
                    setPlanId(selectedPlan?.id);

                    if (selectedPlan?.Planfrequencys?.length > 0) {
                      // Check if localCurrency matches any Planfrequency
                      const matchingPlanFrequency = selectedPlan.Planfrequencys.find(
                        (freq: any) => freq.currency === localCurrency
                      );

                      if (matchingPlanFrequency) {
                        // Use the matching Planfrequency
                        setValue([
                          matchingPlanFrequency.start_range,
                          matchingPlanFrequency.end_range,
                        ]);
                        setCurrSymbol(matchingPlanFrequency.currency);
                      } else {
                        // If localCurrency doesn't match, fallback to USD if available
                        const usdPlanFrequency = selectedPlan.Planfrequencys.find(
                          (freq: any) => freq.currency === "USD"
                        );

                        if (usdPlanFrequency) {
                          setValue([usdPlanFrequency.start_range, usdPlanFrequency.end_range]);
                          setCurrSymbol("USD");
                        } else {
                          // If USD is not available, fallback to the first Planfrequency
                          const defaultPlanFrequency = selectedPlan.Planfrequencys[0];
                          setValue([
                            defaultPlanFrequency.start_range,
                            defaultPlanFrequency.end_range,
                          ]);
                          setCurrSymbol(defaultPlanFrequency.currency || "USD");
                        }
                      }
                    } else {
                      // If no Planfrequencys are available
                      setValue([0, 0]);
                      setCurrSymbol("USD");
                    }
                  }}
                >
                  {location?.state?.plans.map((plan: any, index: number) => (
                    <option key={plan.id} value={index}>
                      {plan.title}
                    </option>
                  ))}
                </Form.Select>




                <div className={`${cx.selectBoxButton}`}>
                  <Button className={`${cx.selectPlanButton}`}
                    onClick={() => {
                      navigate.push({
                        pathname: '/student/plan-select',
                        state: { location }
                      })
                    }}
                  >Compare your Plan?</Button>
                </div>
              </div>
            </Col>

            <h6 className="mt-3">Price Range</h6>
            <Col className="col-12">
              <Form.Group
                className={`${st.formField} ${st.leftIcon}`}
                controlId="">
                <Form.Label>
                  {currSymbol || location?.state?.plans[0]?.Planfrequencys[0]?.currency} {value[0] || location?.state?.plans[0]?.Planfrequencys[0]?.start_range} - {currSymbol || location?.state?.plans[0]?.Planfrequencys[0]?.currency} {value[1] || location?.state?.plans[0]?.Planfrequencys[0]?.end_range}
                </Form.Label>
              </Form.Group>
            </Col>

            <h6>Preference 1</h6>
            <Row>
              <Col className="col-6">
                <Form.Group
                  onSubmit={handleSubmit(classDetails)}
                  className={`${st.formField} ${st.leftIcon}`}
                  controlId="">
                  <Form.Label>Select date</Form.Label>
                  <div className="position-relative">
                    <div className={`${st.actionFirst}`}>
                      <FaCalendarAlt />
                    </div>
                    <DatePicker
                      className="form-control"
                      placeholderText="Select Date"
                      // minDate={subDays(new Date(FinalDate), -2)}
                      selected={classSchedule[0]?.selectedDateForDatePicker}
                      onChange={(date: Date) => {
                        handleChangeDate(date, 0);
                        setStartDate(date);
                        setDisabled1(false);
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col className="col-6">
                <Form.Group
                  className={`${st.formField} ${st.leftIcon}`}
                  controlId="">
                  <Form.Label>Select time</Form.Label>
                  <div className="position-relative">
                    <div className={`${st.actionFirst}`}>
                      <MdOutlineAccessTime />
                    </div>
                    <Form.Control
                      type="time"
                      placeholder="08/09/2022"
                      disabled={disabled1}
                      onChange={(e) => {
                        handleChangeTime(e.target.value, 0);
                        setDisabled2(false);
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <h6 className="mt-3">Preference 2</h6>
            <Row>
              <Col className="col-6">
                <Form.Group
                  className={`${st.formField} ${st.leftIcon}`}
                  controlId="">
                  <Form.Label>Select date</Form.Label>
                  <div className="position-relative">
                    <div className={`${st.actionFirst}`}>
                      <FaCalendarAlt />
                    </div>
                    <DatePicker
                      className="form-control"
                      disabled={disabled2}
                      placeholderText="Select Date"
                      minDate={subDays(new Date(FinalDate), -2)}
                      selected={classSchedule[1]?.selectedDateForDatePicker}
                      onChange={(date: Date) => {
                        handleChangeDate(date, 1);
                        setStartDate(date);
                        setDisabled3(false);
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col className="col-6">
                <Form.Group
                  className={`${st.formField} ${st.leftIcon}`}
                  controlId="">
                  <Form.Label>Select time</Form.Label>
                  <div className="position-relative">
                    <div className={`${st.actionFirst}`}>
                      <MdOutlineAccessTime />
                    </div>
                    <Form.Control
                      type="time"
                      placeholder="08/09/2022"
                      disabled={disabled3}
                      onChange={(e) => {
                        handleChangeTime(e?.target?.value, 1);
                        setDisabled4(false);
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <h6 className="mt-3">Preference 3</h6>
            <Row>
              <Col className="col-6">
                <Form.Group
                  className={`${st.formField} ${st.leftIcon}`}
                  controlId="">
                  <Form.Label>Select date</Form.Label>
                  <div className="position-relative">
                    <div className={`${st.actionFirst}`}>
                      <FaCalendarAlt />
                    </div>
                    <DatePicker
                      className="form-control"
                      disabled={disabled4}
                      placeholderText="Select Date"
                      minDate={subDays(new Date(FinalDate), -2)}
                      selected={classSchedule[2]?.selectedDateForDatePicker}
                      onChange={(date: Date) => {
                        handleChangeDate(date, 2);
                        setStartDate(date);
                        setDisabled5(false);
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col className="col-6">
                <Form.Group
                  className={`${st.formField} ${st.leftIcon}`}
                  controlId="">
                  <Form.Label>Select time</Form.Label>
                  <div className="position-relative">
                    <div className={`${st.actionFirst}`}>
                      <MdOutlineAccessTime />
                    </div>
                    <Form.Control
                      type="time"
                      placeholder="08/09/2022"
                      disabled={disabled5}
                      onChange={(e) => {
                        handleChangeTime(e.target.value, 2);
                        setDisabled6(false);
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col className="col-12">
                <div className={`${cx.profileEdit}`}>
                  <label htmlFor="">Preferred Time(Regular Sessions)</label>
                  <Form.Select aria-label="Default select example"
                    defaultValue={localData.currency}
                    key={props?.currency}
                    onChange={(e) => {
                      setPreferred_time(e.target.value)
                    }}>
                    <option value="">Preferred Time</option>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                  </Form.Select>
                </div>
              </Col>

              <h6 className="mt-3">Curriculum</h6>
              <Col className="col-12">
                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control placeholder="Enter Curriculum"
                    onChange={(e) => {
                      setCurriculam(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>

              <h6 className="mt-3">Preferred Demo Topic</h6>
              <Col className="col-12">
                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control
                    as="textarea"
                    placeholder="Topic"
                    style={{ height: "100px" }}
                    defaultValue={localData.preferred_topic}
                    key={props?.preferred_topic}
                    onChange={(e) => {
                      setPreferred_topic(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>

              {plan_id && classSchedule?.[0]?.select_date && classSchedule?.[0]?.select_time ? <Col className="col-12">
                {disablebtn === true ? <Button
                  disabled
                  className={`btn mt-2 ${cx.bookBtn}`}
                  onClick={() => {
                    if (validateClassSchedule()) {
                      classDetails();
                    }

                  }}>
                  {loadingSpinner}
                </Button> : <Button
                  className={`btn mt-2 ${cx.bookBtn}`}
                  onClick={() => {
                    if (validateClassSchedule()) {
                      classDetails();
                    }
                  }}>
                  {loadingSpinner}
                </Button>}
              </Col> :
                <Col className="col-12">
                  <Button
                    disabled
                    className={`btn mt-2 ${cx.bookBtn}`}>
                    {loadingSpinner}
                  </Button>
                </Col>}
            </Row>

          </div >
        </div >
      </div >
    </>
  );
}
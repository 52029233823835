import React, { useEffect, useState } from "react";
import st from "../../../style.module.scss";
import cx from "./PlanSeclect.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Row, Form, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import { FaTimes } from "react-icons/fa";

export default function PlanSeclect() {
  const location: any = useLocation();
  let localData = JSON.parse(localStorage.getItem("userData")!);
  const navigate = useHistory();

  const [currency, setCurrency] = useState<any>(location?.state?.location?.state?.plans[0]?.Planfrequencys);
  const [plans, setPlans] = useState<any>(location?.state?.location?.state?.plans);
  const [selectCurr, setselectCurr] = useState<any>(location?.state?.location?.state?.plans[0]?.Planfrequencys[0]);
  const [secondCurr, setsecondCurr] = useState<any>(location?.state?.location?.state?.plans[1]?.Planfrequencys[0]);
  const [thirdCurr, setthirdCurr] = useState<any>(location?.state?.location?.state?.plans[2]?.Planfrequencys[0]);

  const descOne = location?.state?.location?.state?.plans[0]?.description;
  const descTwo = location?.state?.location?.state?.plans[1]?.description;
  const descThree = location?.state?.location?.state?.plans[2]?.description;

  const createMarkup = (htmlString: any) => {
    return { __html: htmlString };
  };

  const getDefaultCurrency = (frequencies: any[]) => {
    const userCurrency = localData?.currency?.split("-")[0] || "USD";
    return (
      frequencies.find((item: any) => item.currency === userCurrency) ||
      frequencies.find((item: any) => item.currency === "USD") ||
      frequencies[0]
    );
  };

  useEffect(() => {
    if (plans) {
      setCurrency(plans[0]?.Planfrequencys || []);
      setselectCurr(getDefaultCurrency(plans[0]?.Planfrequencys || []));
      setsecondCurr(getDefaultCurrency(plans[1]?.Planfrequencys || []));
      setthirdCurr(getDefaultCurrency(plans[2]?.Planfrequencys || []));
    }
  }, [plans]);

  return (
    <>
      <Header />
      <section className={`${st.pageContent} ${cx.planSelectPage}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.marginTop}`}></div>
          <div className={`${cx.planselect}`}>
            <Container className={`${cx.mobilePadding}`}>
              <FaTimes onClick={() => navigate.goBack()} />
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <div className={`${cx.planselectBox}`}>
                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">{plans?.[0]?.title}</label>
                    </div>

                    <div className={`${cx.textAreaAdmin}`}>
                      <textarea style={{ resize: "none", height: "300px" }} className={`form-select`} />
                      <div className={`${cx.areaContentTop}`} dangerouslySetInnerHTML={createMarkup(descOne)} />
                    </div>

                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">Price Per Session/Per Hour</label>
                      <Form.Select
                        value={JSON.stringify(selectCurr)}
                        onChange={(e: any) => setselectCurr(JSON.parse(e.target.value))}
                      >
                        {currency.map((item: any) => (
                          <option key={item?.currency} value={JSON.stringify(item)}>
                            {item?.currency}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <div className={`${cx.planList}`}>
                      <ul>
                        <li>
                          <span>0-4 Sessions:</span>
                          <input type="text" value={selectCurr?.one_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>5-9 Sessions:</span>
                          <input type="text" value={selectCurr?.second_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>10-15 Sessions:</span>
                          <input type="text" value={selectCurr?.third_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>16-24 Sessions:</span>
                          <input type="text" value={selectCurr?.four_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>25-50 Sessions:</span>
                          <input type="text" value={selectCurr?.five_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>51 or more Sessions:</span>
                          <input type="text" value={selectCurr?.six_session || ""} className={`form-control`} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                {/* 2nd Column */}
                <Col lg={4} md={6} sm={12} className={`${cx.webShow}`}>
                  <div className={`${cx.planselectBox}`}>
                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">{plans?.[1]?.title}</label>
                    </div>

                    <div className={`${cx.textAreaAdmin}`}>
                      <textarea style={{ resize: 'none', height: '300px' }} className={`form-select`} />
                      <div className={`${cx.areaContentTop}`} dangerouslySetInnerHTML={createMarkup(descTwo)} />
                    </div>

                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">Price Per Session/Per Hour</label>
                      <Form.Select
                        value={JSON.stringify(secondCurr)} // Ensure the value is updated based on the selected currency
                        onChange={(e) => {
                          setsecondCurr(JSON.parse(e.target.value)); // Update currency selection for second plan
                        }}
                      >
                        {plans[1].Planfrequencys.map((item: any) => (
                          <option key={item?.currency} value={JSON.stringify(item)}>
                            {item?.currency}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <div className={`${cx.planList}`}>
                      <ul>
                        <li>
                          <span>0-4 Sessions:</span>
                          <input type="text" value={secondCurr?.one_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>5-9 Sessions:</span>
                          <input type="text" value={secondCurr?.second_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>10-15 Sessions:</span>
                          <input type="text" value={secondCurr?.third_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>16-24 Sessions:</span>
                          <input type="text" value={secondCurr?.four_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>25-50 Sessions:</span>
                          <input type="text" value={secondCurr?.five_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>51 or more Sessions:</span>
                          <input type="text" value={secondCurr?.six_session || ""} className={`form-control`} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                {/* 3rd Column */}
                <Col lg={4} md={6} sm={12} className={`${cx.webShow}`}>
                  <div className={`${cx.planselectBox}`}>
                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">{plans?.[2]?.title}</label>
                      <Form.Select value={JSON.stringify(thirdCurr)} className={`${cx.mobileSHow}`} onChange={(e) => setthirdCurr(JSON.parse(e.target.value))}>
                        {plans[2]?.Planfrequencys?.map((item: any) => (
                          <option key={item?.currency} value={JSON.stringify(item)}>
                            {item?.currency}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <div className={`${cx.textAreaAdmin}`}>
                      <textarea style={{ resize: 'none', height: '300px' }} className={`form-select`} />
                      <div className={`${cx.areaContentTop}`} dangerouslySetInnerHTML={createMarkup(descThree)} />
                    </div>

                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">Price Per Session/Per Hour</label>
                      <Form.Select value={JSON.stringify(thirdCurr)} onChange={(e) => setthirdCurr(JSON.parse(e.target.value))}>
                        {plans[2].Planfrequencys.map((item: any) => (
                          <option key={item?.currency} value={JSON.stringify(item)}>
                            {item?.currency}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <div className={`${cx.planList}`}>
                      <ul>
                        <li>
                          <span>0-4 Sessions:</span>
                          <input type="text" value={thirdCurr?.one_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>5-9 Sessions:</span>
                          <input type="text" value={thirdCurr?.second_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>10-15 Sessions:</span>
                          <input type="text" value={thirdCurr?.third_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>16-24 Sessions:</span>
                          <input type="text" value={thirdCurr?.four_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>25-50 Sessions:</span>
                          <input type="text" value={thirdCurr?.five_session || ""} className={`form-control`} />
                        </li>
                        <li>
                          <span>51 or more Sessions:</span>
                          <input type="text" value={thirdCurr?.six_session || ""} className={`form-control`} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className={`pt-3 ${st.spaceBox}`}></div>
        </div>
      </section>
      <Footer />
    </>
  );
}
